import React, { createRef, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useStateRef from "react-usestateref";
import { WebRtcService } from "../../services/WebRtcService";
import { UEConnectionService } from "../../services/UEConnectionService";
import { ErrorCodes, ToClientMessageType } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import AfkMonitor from "./AfkMonitor";
import { isMobile, isMobileOnly } from "react-device-detect";
import NetworkChecker from "./NetworkChecker";
import { log, LogLevel } from "../../helpers/logger";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import {
  selectPlayerId,
  selectSignallingService,
  actions,
} from "../player/playerSlice";
import {
  isFullscreen,
  setFullscreenListener,
  startFullscreenElement,
  supportsFullscreen,
  supportsVibrate,
} from "../../helpers/device";
import { getItem, setItem } from "../../services/Storage";
import { usePageVisibility } from "react-page-visibility";
import PlayerDataModal from "../../components/PlayerDataModal";
import { useQueryParam, StringParam } from "use-query-params";
import UEConsole from "./UEConsole";

const statusTypes = {
  ready: 1,
  loading: 2,
  loaded: 3,
  playing: 4,
  streamerDisconnected: 5,
  error: 6,
  waiting: 7,
};

const WebRTCPlayer = function (props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const signallingService = useSelector(selectSignallingService);

  const videoPlayerRef = createRef();
  const audioPlayerRef = createRef();
  const videoContainerRef = createRef();

  const [signallingConn, setSignallingConn] = useState(null);
  const [streamerConnected, setStreamerConnected] = useState(false);
  const [webRtcConn, setWebRtcConn] = useState(null);
  const [ueConn, setUeConn, ueConnRef] = useStateRef(null);
  const [statusText, setStatusText] = useState();
  const [buttonText, setButtonText] = useState();
  const [status, setStatus, statusRef] = useStateRef(statusTypes.ready);
  const [statusIsTransitioning, setStatusIsTransitioning] = useState();
  const [isChangingScene, setIsChangingScene] = useState(false);
  const [showCursor, setShowCursor] = useState(true);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const soundMode = getItem("apprendly:webplayer:soundMode");
  const [muted, setMuted] = useState(soundMode && soundMode === "off");
  const [playerEl, setPlayerEl, playerElRef] = useStateRef(null);
  const [showQuitConfirmation, setShowQuitConfirmation] = useState(false);
  const [showConsoleInput, setShowConsoleInput] = useState(false);
  const [consoleValue, setConsoleValue] = useState(false);
  const windowIsVisible = usePageVisibility();

  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const [shouldDisableInput, setShouldDisableInput] = useState(true);

  const playerId = useSelector(selectPlayerId);

  const [playerData] = useQueryParam("playerData", StringParam);

  useEffect(() => {
    if (status === statusTypes.playing) {
      setStatusIsTransitioning(true);
      setTimeout(() => setStatusIsTransitioning(false), 500);
    }
  }, [status]);

  useEffect(() => {
    if (buttonText?.length) {
      setStatusText(null);
    }
  }, [buttonText]);

  useEffect(() => {
    if (statusText?.length) {
      setButtonText(null);
    }
  }, [statusText]);

  /**
   * Start playing the game.
   */
  const startPlaying = useCallback(() => {
    setStatus(statusTypes.loading);
    setStatusText(t("player:connecting") + "...");
    log(LogLevel.debug, "Connecting to streamer", 3);
    signallingService.sendMessage("connectToStreamer");

    /* Do not show modal if playerData is set */
    /* This is set as a query param on embed.js */
    if (
      !playerData &&
      !getItem("apprendly:webplayer:playerData") &&
      props.askForPlayerName &&
      props.enableTracking
    ) {
      setShouldDisableInput(false);
      setTimeout(() => {
        setOpenPlayerModal(true);
      }, 2500);
    }
  }, [setStatus, signallingService, t]);

  const updatePlayerData = (inputPlayerData) => {
    const inputParts = inputPlayerData.split(" ");
    const [firstName, lastName] =
      inputParts.length > 1
        ? [
            inputParts.slice(0, -1).join(" "), // Join all elements except the last one
            inputParts[inputParts.length - 1], // Get the last element
          ]
        : inputParts;
    const playerData = { firstName, lastName };
    sendGameMessage({
      type: "gameEvent",
      eventName: "playerDataInserted",
      data: { apprendly: true, playerData },
    });

    /* Store value in localStorage */
    setItem("apprendly:webplayer:playerData", playerData);

    signallingService.sendMessage("playerDataAdded", playerData);

    /* Re-enable inputs */
    if (ueConn) {
      ueConn.controller.registerKeyboardEvents();
      ueConn.controller.registerTouchEvents();
    }

    setOpenPlayerModal(false);
  };

  const closePlayerDataModal = () => {
    /* Re-enable inputs */
    if (ueConn) {
      ueConn.controller.registerKeyboardEvents();
      ueConn.controller.registerTouchEvents();
    }

    setOpenPlayerModal(false);
  };

  /**
   * If in autoplay mode, start playing as soon as we're ready.
   */
  useEffect(() => {
    if (props.autostart && status === statusTypes.ready) {
      startPlaying();

      if (isMobileOnly) {
        setFullscreenMode(true);
      }
    }
  }, [status, props.autostart, startPlaying]);

  /**
   * Send a message regarding the game or player, that can be forwareded to the embed or website.
   *
   * @param {*} data
   */
  const sendGameMessage = useCallback(
    (data) => {
      if (props.onGameMessage) {
        props.onGameMessage(data);
      }
    },
    [props]
  );

  const lockCursor = () => {
    if (
      !showCursor &&
      webRtcConn?.playerEl &&
      webRtcConn.playerEl.requestPointerLock
    ) {
      webRtcConn.playerEl.requestPointerLock();
    }
  };

  const exitPointerLock = () => {
    if (document.exitPointerLock) {
      document.exitPointerLock();
    }
  };

  /**
   * Set up controls for the player with proper translation from click position to screen position in game.
   *
   * Needs to be run after any change of the player size or position.
   */
  const normalizeAndQuantize = () => {
    if (playerElRef.current && ueConnRef.current?.controller) {
      const videoRes = {
        width: playerElRef.current.videoWidth,
        height: playerElRef.current.videoHeight,
      };

      const playerRes = playerElRef.current.getBoundingClientRect();

      ueConnRef.current.controller.setupNormalizeAndQuantize(
        videoRes,
        playerRes
      );
    }
  };

  const normalizeAndQuantizeDebounced = useCallback(
    debounce(normalizeAndQuantize, 500),
    []
  );

  useEffect(() => {
    if (windowIsVisible) {
      normalizeAndQuantizeDebounced();
    }
  }, [windowIsVisible, normalizeAndQuantizeDebounced]);

  /**
   * Get the desired video resolution based on the size of the video player on screen.
   *
   * @param {*} videoPlayerEl
   * @returns
   */
  const getDesiredResolution = (videoPlayerEl) => {
    const vSize = videoPlayerEl.getBoundingClientRect();

    // Set a pixel ratio between 1.3 and 2, depending on device
    const pixelRatio = Math.min(
      2,
      Math.max(window.devicePixelRatio || 1.3, 1.3)
    );

    let width = Math.max(vSize.width, vSize.height) * pixelRatio,
      height = Math.min(vSize.width, vSize.height) * pixelRatio;

    if (!isMobile) {
      if (height > width * 0.75) {
        height = width * 0.75;
      } else if (height < width / 2) {
        width = height * 2;
      }
    }

    return {
      width,
      height,
    };
  };

  const requestLockCursor = useCallback(
    (force) => {
      if (
        (!showCursor || force) &&
        webRtcConn?.playerEl &&
        webRtcConn.playerEl.requestPointerLock
      ) {
        webRtcConn.playerEl.requestPointerLock();
      }
    },
    [showCursor, webRtcConn]
  );

  /**
   * Setting up the UE connection with event listeners.
   */
  const setupUeConn = useCallback(
    (ueConn) => {
      // const videoPlayerEl = webRtcConn.playerEl;

      ueConn.controller.registerMouseEnterAndLeaveEvents();

      ueConn.setEventListener("connectionOpened", () => {
        log(LogLevel.debug, "UE Connection opened");

        setStatusText(t("player:loading") + "...");
        setStatus(statusTypes.loading);
        setPlayerEl(webRtcConn.playerEl);

        // webRtcConn.aggregateStats(2000);
        // webRtcConn.addEventListener("aggregatedStats", (stats) =>
        //   console.log(stats)
        // );

        console.log("Setting interval: normalizeAndQuantizeDebounced");

        setInterval(normalizeAndQuantizeDebounced, 800);

        const openGameSettings = {
          Id: props.gameId,
          EmbedId: props.embedId,
          PlayerId: playerId,
          EnableTracking: props.enableTracking,
        };

        setTimeout(
          () => ueConn.sendCommand("OpenGame", openGameSettings),
          1000
        );

        log(LogLevel.debug, "Opening game", openGameSettings);

        // ueConn.sendCommand("SetDpiScaling", {
        //   Scale:
        //     !isMobile || window.devicePixelRatio <= 1
        //       ? 1
        //       : Math.max(1, Math.min(1.35, window.devicePixelRatio * 0.7)),
        // });

        window.addEventListener("resize", () => {
          setTimeout(normalizeAndQuantizeDebounced, 300);
        });

        window.addEventListener(
          "orientationchange scroll",
          normalizeAndQuantizeDebounced
        );

        webRtcConn.playerEl.addEventListener(
          "resize",
          normalizeAndQuantizeDebounced
        );

        normalizeAndQuantizeDebounced();

        if (shouldDisableInput) {
          ueConn.controller.registerKeyboardEvents();
          ueConn.controller.registerTouchEvents();
        }
      });

      ueConn.setEventListener("onMessage", ({ type, data }) => {
        switch (type) {
          case ToClientMessageType.Response:
            const received = new TextDecoder("utf-16").decode(data);
            let response;
            if (received) {
              try {
                response = JSON.parse(received);
              } catch (e) {
                response = received;
              }
            }

            switch (response.Type) {
              case "ScenarioLoaded":
                if (statusRef.current === statusTypes.loading) {
                  setTimeout(() => {
                    if (response.Settings.MouseVisible && !props.autostart) {
                      ueConn.sendCommand("InitPlayerControls", {});
                      normalizeAndQuantizeDebounced();
                      setStatus(statusTypes.playing);
                    } else {
                      setStatus(statusTypes.loaded);
                      setButtonText(t("player:startGame"));
                    }
                  }, 3000);

                  setShowCursor(response.Settings.MouseVisible);
                }

                break;

              case "QuitGame":
                setStatus(statusTypes.streamerDisconnected);
                setStatusText(t("player:gameEnded"));
                exitPointerLock();

                if (props.onQuitGame) {
                  props.onQuitGame();
                }

                setTimeout(() => {
                  ueConn.webRtcConn.close();
                  setStreamerConnected(false);
                  signallingService.sendMessage("disconnectedFromStreamer", {
                    reason: "quitGame",
                  });
                }, 1500);

                break;
              case "MouseVisibility":
                const visible = response.Visible;
                setShowCursor(visible);

                if (visible) {
                  ueConn.controller.registerHoveringMouseEvents();
                  exitPointerLock();
                } else {
                  ueConn.controller.registerLockedMouseEvents();
                  requestLockCursor(true);
                }

                break;
              case "GameEvent":
                sendGameMessage({
                  type: "gameEvent",
                  eventName: response.EventName,
                  data: response.Data,
                });

                log(
                  LogLevel.debug,
                  "Received game event",
                  response.EventName,
                  response.Data
                );

                if (response.EventName === "playthroughReady") {
                  const playthroughInfo = {
                    playthroughId: response.Data.id,
                    gameId: props.gameId,
                    gameName: props.gameName,
                    playerId: props.playerId,
                  };

                  signallingService.sendMessage(
                    "playthroughStarted",
                    playthroughInfo
                  );
                  log(
                    LogLevel.debug,
                    "Sending playthrough info to signalling",
                    playthroughInfo
                  );
                }

                if (response.EventName === "sceneChange") {
                  setIsChangingScene(true);
                } else if (response.EventName === "sceneStarted") {
                  setIsChangingScene(false);
                }

                break;

              case "ToggleConsole":
                setShowConsoleInput(response.Enabled);
                break;

              case "ConsoleValue":
                log(LogLevel.debug, "Received console value", response);
                setConsoleValue({
                  name: response.ValueName,
                  value: response.Value,
                });
                break;

              default:
                log(
                  LogLevel.debug,
                  "Unknown response type from streamer",
                  response
                );
            }
            break;

          case ToClientMessageType.Command:
            // let commandAsString = new TextDecoder("utf-16").decode(data);
            // console.log(commandAsString);
            // let command = JSON.parse(commandAsString);
            // if (command.command === "onScreenKeyboard") {
            //   showOnScreenKeyboard(command);
            // }
            break;

          case ToClientMessageType.FreezeFrame:
            // freezeFrame.size = new DataView(data.slice(2, 6).buffer).getInt32(
            //   0,
            //   true
            // );
            // freezeFrame.jpeg = data.slice(6);
            // if (freezeFrame.jpeg.length < freezeFrame.size) {
            //   console.log(
            //     `received first chunk of freeze frame: ${freezeFrame.jpeg.length}/${freezeFrame.size}`
            //   );
            //   freezeFrame.receiving = true;
            // } else {
            //   console.log(
            //     `received complete freeze frame: ${freezeFrame.jpeg.length}/${freezeFrame.size}`
            //   );
            //   showFreezeFrame();
            // }
            break;

          case ToClientMessageType.UnfreezeFrame:
            // invalidateFreezeFrameOverlay();
            break;

          case ToClientMessageType.VideoEncoderAvgQP:
            // let VideoEncoderQP = new TextDecoder("utf-16").decode(data.slice(1));
            break;

          case ToClientMessageType.LatencyTest:
            break;

          default:
            console.error(
              `unrecognised data received, packet ID ${type}`,
              data
            );
        }
      });
    },
    [
      webRtcConn,
      setStatus,
      t,
      requestLockCursor,
      sendGameMessage,
      signallingService,
      props,
      statusRef,
      playerId,
      normalizeAndQuantizeDebounced,
      setPlayerEl,
      shouldDisableInput,
    ]
  );

  /**
   * Setting up the listeners for WebRTC messages and events.
   */
  const setupWebRtcListeners = useCallback(() => {
    log(LogLevel.debug, "Setting up webrtc listeners");

    if (!ueConn) {
      log(LogLevel.debug, "Setting up UE Connection");

      const ueConnection = new UEConnectionService(
        webRtcConn,
        webRtcConn.playerEl
      );

      setUeConn(ueConnection);
      setupUeConn(ueConnection);
    }

    webRtcConn.setEventListener("dataChannelConnected", () => {});

    webRtcConn.setEventListener("webRtcOffer", (offer) => {
      signallingService.sendMessage("offer", offer);
    });

    webRtcConn.setEventListener("webRtcAnswer", (answer) => {
      log(LogLevel.debug, "Sending answer", answer);
      signallingService.sendMessage("answerLegacy", { answer });
    });

    webRtcConn.setEventListener("webRtcCandidate", (candidate) => {
      signallingService.sendMessage("iceCandidateLegacy", {
        candidate,
      });
    });

    webRtcConn.playerEl.onclick = () => {
      requestLockCursor();
    };

    // webRtcConn.createOffer();
  }, [webRtcConn, ueConn, requestLockCursor, setupUeConn, signallingService]);

  const onSignallingServiceMessage = useCallback(
    (type, data) => {
      log(LogLevel.debug, "Received signalling message", type, data);

      const onConfigReceived = (config) => {
        webRtcConn.setConfig(config.peerConnectionOptions);
      };

      const onWebRtcAnswerReceived = (webRtcData) => {
        webRtcConn.receiveAnswer(webRtcData);
      };

      const onIceCandidateReceived = (candidate) => {
        webRtcConn.handleCandidateFromServer(candidate);
      };

      switch (type) {
        case "streamerConnected":
          log(LogLevel.debug, "Streamer connected");
          setStreamerConnected(true);
          setupWebRtcListeners();

          sendGameMessage({
            type: "streamerConnected",
            streamer: data.streamer,
          });

          if (status !== statusTypes.loading) {
            setStatus(statusTypes.loading);
            setStatusText(t("player:connecting") + "...");
          }

          break;
        case "streamerDisconnected":
          log(LogLevel.debug, "Streamer disconnected", status, data);

          if (data.reason === "playthroughTimeout") {
            setStatus(statusTypes.streamerDisconnected);
            setStatusText(t("player:streamerDisconnectedTimeout"));
            setStreamerConnected(false);
            setWebRtcConn(null);
            return;
          }

          // Check if we're still actually receiving data. If we are, this is a false
          // If still loading while the streamer disconnects, try to find a new one
          if (status === statusTypes.loading) {
            console.log(type, data);
            log(LogLevel.debug, "Connecting to streamer", 1);
            setStatus(statusTypes.waiting);
            setStatusText(t("player:connecting") + "...");
            setStreamerConnected(false);
            setWebRtcConn(null);
            signallingService.sendMessage("connectToStreamer");
            return;
          }

          setStatus(statusTypes.streamerDisconnected);
          setStreamerConnected(false);
          setStatusText(t("player:streamerDisconnected"));
          break;
        case "couldNotConnectToStreamer":
          if (data.reason === "noStreamerAvailable") {
            // Set to waiting for connection, but show message about no server after 5 minutes
            setTimeout(() => {
              setStatus((status) => {
                if (status === statusTypes.waiting) {
                  setStatusText(t("player:couldNotConnectToServerTryAgain"));
                  dispatch(actions.setError(ErrorCodes.NoStreamerAvailable));
                  return statusTypes.error;
                }

                return status;
              });
            }, 1000 * 60 * 5);
            setStatus(statusTypes.waiting);
            setStatusText(t("player:noStreamerAvailable"));
          } else {
            setStatus(statusTypes.error);
            setStatusText(t("player:couldNotConnectToServerTryAgain"));
          }
          break;
        case "newStreamerAvailable":
          log(LogLevel.debug, "New streamer available", statusRef.current);
          setTimeout(() => {
            if (statusRef.current === statusTypes.waiting) {
              log(LogLevel.debug, "Connecting to streamer", 2);
              signallingService.sendMessage("connectToStreamer");
              setStatus(statusTypes.loading);
            }
          }, 500);
          break;
        case "config":
          log(LogLevel.debug, "Config received", type, data);
          onConfigReceived(data);
          break;
        case "answer":
          log(LogLevel.debug, "Received answer", type, data);
          onWebRtcAnswerReceived(data);
          break;
        case "offer":
          log(LogLevel.debug, "Received offer", type, data);
          webRtcConn.receiveOffer(data);
          break;
        case "iceCandidate":
          log(LogLevel.debug, "Received ICE candidate", type, data);
          onIceCandidateReceived(data.candidate);
          break;
        default:
          log(LogLevel.debug, "Unknown message type: ", type, data);
      }
    },
    [
      status,
      statusRef,
      setStatus,
      setupWebRtcListeners,
      t,
      webRtcConn,
      signallingService,
      dispatch,
    ]
  );

  /**
   * Set up connection to the signalling server.
   */
  const setupSignalling = useCallback(() => {
    signallingService.addEventListener("disconnect", () => {
      setStatusText(
        statusRef.current === statusTypes.playing
          ? t("player:lostConnectionToServer")
          : t("player:couldNotConnectServer")
      );
      setStatus(statusTypes.error);
      setSignallingConn(null);
      setStreamerConnected(false);
    });

    signallingService.setEventListener("connect", () => {
      setButtonText(t("player:startGame"));
      setStatus(statusTypes.ready);

      signallingService.sendMessage("addPlayerInfo", {
        gameId: props.gameId,
        gameName: props.gameName,
        embedId: props.embedId,
      });
    });

    setSignallingConn(true);
    log(LogLevel.debug, "Setting up signalling connection");
  }, [t, statusRef, setStatus, signallingService]);

  useEffect(() => {
    signallingService.setEventListener("message", onSignallingServiceMessage);
  }, [status, onSignallingServiceMessage, signallingService]);

  /**
   * When player is ready, set up connection for Signalling and WebRTC.
   */
  useEffect(() => {
    if (webRtcConn && !signallingConn && status !== statusTypes.error) {
      setupSignalling();
    }
    if (!webRtcConn && videoPlayerRef.current) {
      const webRtcService = new WebRtcService();
      webRtcService.setPlayer(videoPlayerRef.current, audioPlayerRef.current);
      webRtcService.enableMic = props.enableMic;
      setWebRtcConn(webRtcService);
    }
  }, [
    props.gameId,
    props.enableMic,
    webRtcConn,
    signallingConn,
    status,
    setupSignalling,
    videoPlayerRef,
  ]);

  /**
   * On click on the video player overlay.
   */
  const overlayClicked = (e) => {
    switch (status) {
      case statusTypes.ready:
        videoPlayerRef.current.play();
        audioPlayerRef.current.play();
        startPlaying();

        if (props.onStartLoading) {
          props.onStartLoading();
        }

        break;

      case statusTypes.streamerDisconnected:
      case statusTypes.error:
        setStatus(statusTypes.loading);
        setStatusText(t("player:connecting") + "...");
        console.log(signallingConn, videoPlayerRef.current);
        videoPlayerRef.current.play();
        audioPlayerRef.current.play();

        if (signallingConn) {
          log(LogLevel.debug, "Connecting to streamer", 4);
          signallingService.sendMessage("connectToStreamer");
        } else {
          setupSignalling();
        }
        break;
      case statusTypes.loaded:
        setStatus(statusTypes.playing);
        setStatusText(null);
        ueConn.sendCommand("InitPlayerControls", {});

        setTimeout(() => {
          lockCursor();
          webRtcConn.playerEl.play();
          webRtcConn.audioEl.play();

          normalizeAndQuantizeDebounced();
        }, 200);

        if (props.onStartPlaying) {
          props.onStartPlaying();
        }
        break;
      case statusTypes.playing:
        lockCursor();
        break;
      default:
        break;
    }
  };

  /**
   * Trigger fullscreen.
   */
  const triggerFullscreen = () => {
    if (!props.allowFullscreen) {
      return;
    }

    if (fullscreenMode) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }

      if (!supportsFullscreen()) {
        sendGameMessage({ type: "setFullscreen", fullscreen: false });
      }

      setFullscreenMode(false);
      return;
    }

    const fullscreenEl = videoContainerRef.current;
    if (startFullscreenElement(fullscreenEl)) {
      if (status === statusTypes.playing) {
        setTimeout(() => lockCursor, 500);
      }
    }

    setFullscreenMode(true);

    if (!supportsFullscreen()) {
      sendGameMessage({ type: "setFullscreen", fullscreen: true });
    }
  };

  /**
   * Quit Game from web player quit button.
   */
  const quitGame = () => {
    setStatus(statusTypes.streamerDisconnected);
    setStreamerConnected(false);
    // setStatusText(t("player:timeoutDisconnected"));
    exitPointerLock();
    signallingService.sendMessage("disconnectedFromStreamer", {
      reason: "userQuit",
    });
    if (props.onQuitGame) {
      props.onQuitGame();
    }
    setStatus(statusTypes.ready);
    setShowQuitConfirmation(false);
  };

  /**
   * Trigger sound mode.
   */
  const triggerSoundMode = () => {
    setMuted(!muted);
    setItem("apprendly:webplayer:soundMode", muted ? "on" : "off");
  };

  /**
   * Handling fullscreen change.
   *
   * When connected to a game, send the new resolution, and make sure mouse gets locked if relevant.
   */
  const onFullscreenChange = useCallback(() => {
    setFullscreenMode(isFullscreen());
    sendGameMessage({ type: "setFullscreen", fullscreen: isFullscreen() });

    if (ueConn && webRtcConn) {
      setTimeout(normalizeAndQuantizeDebounced, 300);

      if (isFullscreen()) {
        requestLockCursor();
      }
    }
  }, [ueConn, webRtcConn, requestLockCursor, sendGameMessage]);

  useEffect(() => {
    setFullscreenListener(onFullscreenChange);
  }, [onFullscreenChange]);

  const onAfkDisconnect = () => {
    setStatus(statusTypes.error);
    setStreamerConnected(false);
    setStatusText(t("player:timeoutDisconnected"));
    exitPointerLock();
    signallingService.sendMessage("disconnectedFromStreamer", {
      reason: "afk",
    });
  };

  const onAfkWarningClicked = () => {
    // When the AFK warning is clicked, it means the user has chosen to continue playing, and doesn't have pointer lock currently.
    if (status === statusTypes.playing) {
      setStatus(statusTypes.playing);
      setTimeout(() => lockCursor, 100);
    } else {
      overlayClicked();
    }
  };

  return (
    <>
      <div
        className={classNames("video-container w-screen h-screen text-center", {
          fullscreen: fullscreenMode,
        })}
        ref={videoContainerRef}
      >
        {props.askForPlayerName && (
          <PlayerDataModal
            openPlayerModal={openPlayerModal}
            updatePlayerData={updatePlayerData}
            closePlayerDataModal={closePlayerDataModal}
          />
        )}

        <div
          className={classNames(
            "video-overlay w-screen h-screen flex align-middle justify-center absolute opacity-0 bg-dusk",
            {
              "z-50": status !== statusTypes.playing || statusIsTransitioning,
              active: status !== statusTypes.playing,
              transparent: status === statusTypes.loaded,
              error:
                status === statusTypes.error ||
                status === statusTypes.streamerDisconnected,
            }
          )}
          onClick={overlayClicked}
        >
          <div
            className={classNames("self-center transition-opacity", {
              "opacity-0": statusIsTransitioning,
            })}
          >
            {(status === statusTypes.loading ||
              status === statusTypes.waiting) && (
              <div className="loading-spinner-wrapper">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                {/* <div className="loading-spinner"></div> */}
              </div>
            )}
            {statusText?.length > 0 &&
              status !== statusTypes.ready &&
              !(isMobileOnly && status === statusTypes.loaded) && (
                <div className="status-text text-nc-3xl sm:text-3xl font-bold">
                  {statusText}
                </div>
              )}
            {buttonText?.length && (
              <button className="button play-button">{buttonText}</button>
            )}
          </div>
        </div>
        {!isMobileOnly && status !== statusTypes.ready && (
          <div
            onClick={() => setShowQuitConfirmation(true)}
            className="icon-container absolute left-2 top-2 w-10 h-10 z-50"
            title={t("player:quit")}
          >
            <div className="quit-game w-10 h-10"></div>
          </div>
        )}
        {showQuitConfirmation && (
          <div className="w-screen h-screen fixed top-0 left-0 z-50 bg-dusk flex flex-col justify-center align-middle text-center">
            <p className="text-nc-4xl mb-8 font-bold">
              {t("player:quitConfirmation")}
            </p>
            <div className="flex-row">
              <button
                className="button button-lg inline mr-8 font-semibold"
                onClick={quitGame}
              >
                {t("player:quit")}
              </button>
              <button
                className="button button__alternative button-lg inline font-semibold"
                onClick={() => setShowQuitConfirmation(false)}
              >
                {t("player:cancel")}
              </button>
            </div>
          </div>
        )}
        <div
          onClick={triggerSoundMode}
          className="icon-container absolute right-2 top-2 w-10 h-10 z-50"
        >
          <div
            className={classNames("sound-trigger w-10 h-10", {
              "sound-muted": muted,
            })}
            title={muted ? t("player:unmute") : t("player:mute")}
          ></div>
        </div>
        {props.allowFullscreen && !isMobileOnly && !showConsoleInput && (
          <div
            onClick={triggerFullscreen}
            className="icon-container absolute right-2 bottom-2 w-10 h-10 z-50"
          >
            <div
              className={classNames("fullscreen-trigger w-10 h-10", {
                "is-fullscreen": fullscreenMode,
              })}
              title={
                fullscreenMode
                  ? t("player:exitFullscreen")
                  : t("player:startFullscreen")
              }
            ></div>
          </div>
        )}
        {props.gameId && (
          <>
            <video
              className="absolute w-screen h-screen left-0 top-0"
              ref={videoPlayerRef}
              playsInline
              muted={muted}
              disablePictureInPicture={true}
              autoPlay={true}
            ></video>
            <audio
              id="streamingAudio"
              muted={muted}
              ref={audioPlayerRef}
            ></audio>
          </>
        )}
        {streamerConnected && (
          <>
            {(status === statusTypes.playing ||
              status === statusTypes.loaded) && (
              <AfkMonitor
                webRtcConn={webRtcConn}
                onDisconnect={onAfkDisconnect}
                onAfkWarningClicked={onAfkWarningClicked}
                isFullscreen={fullscreenMode}
              />
            )}
            {[statusTypes.loaded, statusTypes.playing].includes(status) &&
              !isChangingScene &&
              ueConn &&
              webRtcConn && <NetworkChecker ueConn={ueConn} />}
          </>
        )}
        {showConsoleInput && (
          <UEConsole
            ueConnection={ueConn}
            toggleVisibility={setShowConsoleInput}
            consoleValue={consoleValue}
          />
        )}
      </div>
    </>
  );
};

WebRTCPlayer.propTypes = {
  gameId: PropTypes.string.isRequired,
  gameName: PropTypes.string,
  embedId: PropTypes.string.isRequired,
  playerId: PropTypes.string,
  allowFullscreen: PropTypes.bool,
  autostart: PropTypes.bool,
  onQuitGame: PropTypes.func,
  onStartLoading: PropTypes.func,
  onStartPlaying: PropTypes.func,
  onGameMessage: PropTypes.func,
  enableTracking: PropTypes.bool,
  askForPlayerName: PropTypes.bool,
  enableMic: PropTypes.bool,
};

export default WebRTCPlayer;
