import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import { log, LogLevel } from "../helpers/logger";
import { localStorageSupported } from "../services/Storage";

const PlayerWarning = (props) => {
  const { t } = useTranslation();

  const [isRemoved, setIsRemoved] = useState(null);

  useEffect(() => {
    const shouldBeRemoved = getRemovedWarnings()[props.warningName];
    setIsRemoved(shouldBeRemoved);
  }, [props.warningName]);

  const getRemovedWarnings = () => {
    if (!localStorageSupported()) {
      return {};
    }

    const fromLs = localStorage.getItem("removeWarnings");

    if (!fromLs) {
      return {};
    }

    return JSON.parse(fromLs);
  };

  const onRemove = () => {
    setIsRemoved(true);

    if (props.rememberRemove && localStorageSupported()) {
      try {
        const warnings = getRemovedWarnings();
        warnings[props.warningName] = true;
        localStorage.setItem("removeWarnings", JSON.stringify(warnings));
      } catch (error) {
        log(LogLevel.error, "Failed to store to localstorage.", error);
      }
    }
  };

  if (!!isRemoved) {
    return null;
  }

  return (
    <div
      className={classNames(
        "absolute top-0 left-0 w-screen h-screen bg-pink flex px-2 sm:px-10 z-50",
        "player-warnings",
        `player-warning-${props.warningName}`,
        {
          fullscreen: props.isForeground,
        }
      )}
    >
      <div className="player-warning text-center self-center">
        {props.children}

        {props.removable && (
          <button
            className="button button__alternative mt-2 sm:mt-5 lg:mt-20"
            onClick={onRemove}
          >
            {t("player:continue")}
          </button>
        )}
      </div>
    </div>
  );
};

PlayerWarning.propTypes = {
  warningName: PropTypes.string.isRequired,
  isForeground: PropTypes.bool,
  removable: PropTypes.bool,
  rememberRemove: PropTypes.bool,
};

export default PlayerWarning;
