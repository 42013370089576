import { ServiceBase } from "./ServiceBase";
import { log, LogLevel } from "../helpers/logger";

export class SignallingService extends ServiceBase {
  socket = null;
  callbacks = {};
  initiated = false;
  paused = false;
  socketHost = "";

  constructor() {
    super();

    const socketProtocol =
      window.location.protocol === "http:" ? "ws:" : "wss:";
    const domain = this.getSocketHostDomain();
    const port = process.env.REACT_APP_CORE_API_PXS_PORT || 3210;

    this.socketHost = `${socketProtocol}//${domain}:${port}`;
  }

  get connected() {
    return this.socket && this.socket.readyState !== WebSocket.CLOSED;
  }

  init() {
    log(
      LogLevel.debug,
      "Init signalling service and connection",
      this.initiated
    );

    if (this.initiated) {
      return;
    }

    this.initiated = true;
    this.socket = new WebSocket(this.socketHost);

    this.socket.addEventListener("open", () => {
      this.callEventListeners("connect");
    });

    this.socket.addEventListener("disconnect", (event) => {
      if (!this.paused) {
        this.callEventListeners("disconnect", event);
      }
    });

    this.socket.addEventListener("message", (message) => {
      const data = JSON.parse(message.data);
      log(
        LogLevel.debug,
        "SignallingService message",
        message,
        data.type,
        data
      );
      this.callEventListeners("message", data.type, data);
    });

    this.socket.addEventListener("error", (error) => {
      console.error("SignallingService error", error);
      this.callEventListeners("error", error);
    });

    this.socket.addEventListener("close", (error) => {
      console.error("SignallingService close event", error);
      this.callEventListeners("error", error);
    });
  }

  getSocketHostDomain = () => {
    let domain = process.env.REACT_APP_CORE_API_PXS_DOMAIN || "pxs.apprend.ly";

    const regionMatches = window.location.host.match(/([a-z-]+)\.play/i);
    if (
      regionMatches &&
      regionMatches[1] &&
      !process.env.PUBLIC_URL.includes(`${regionMatches[1]}.play`)
    ) {
      return `${regionMatches[1]}.${domain}`;
    }

    return domain;
  };

  pause() {
    if (!this.connected) {
      return;
    }

    this.paused = true;
    this.disconnect();
  }

  resume() {
    this.socket.connect();
  }

  disconnect() {
    if (!this.connected) {
      return;
    }

    this.socket.disconnect();
  }

  connect() {
    if (this.connected) {
      return;
    }

    if (!this.initiated) {
      return this.init();
    }

    this.socket.connect();
  }

  sendMessage(type, data) {
    if (this.connected) {
      log(LogLevel.debug, "SignallingService sendMessage", type, data);
      this.socket.send(JSON.stringify({ type, ...data }));
    }
  }

  async getWebRtcConfig() {
    return new Promise((resolve, reject) => {
      if (this.connected) {
        this.socket.send("getConfig", (config) => resolve(config));
      } else {
        reject();
      }
    });
  }
}
